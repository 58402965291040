<template>
  <div class="login md-layout md-alignment-center-center">
    <md-card
      class="md-layout-item md-size-30 md-large-size-60 md-medium-size-70 md-xsmall-size-95 card"
    >
      <div class="md-layout md-alignment-center">
        <img
          alt="Clustag logo"
          class="md-layout-item login-logo md-xsmall-size-80 md-size-60"
          src="../../assets/logo_rielec.png"
        />
      </div>

      <div class="md-layout md-alignment-bottom-center">
        <md-card-header></md-card-header>
        <span class="info error" v-if="$route.params.cause">{{$t($route.params.cause)}}</span>
      </div>

      <div class="md-layout md-alignment-center">
        <login-form :redirect="true" />
      </div>
      <div class="md-layout md-layout-item md-size-100 md-alignment-center">v{{version}}</div>
      <div class="md-layout md-layout-item md-size-100 md-alignment-center"><locale-changer /></div>
    </md-card>
  </div>
</template>

<script>
import LoginForm from "../../components/login/LoginForm";
import LocaleChanger from "../../components/layout/LocaleChanger";
import { VERSION } from "../../common/constants.js";

export default {
  name: "Login",
  components: {
    LoginForm,
    LocaleChanger
  },
  data() {
    return {
      version: VERSION
    };
  }
};
</script>

<style src="./Login.css" scope>
</style>