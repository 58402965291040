<template>
  <form
    class="md-layout-item md-size-60 md-xsmall-size-90"
    id="login-form"
    @submit.prevent="onSubmit(username, password)"
  >
    <md-field v-bind:class="{ 'md-invalid': $v.username.$error }">
      <label for="username">{{ $t("username") }}</label>
      <md-input name="username" v-model="username" autocomplete="off" />
    </md-field>
    <md-field v-bind:class="{ 'md-invalid': $v.password.$error }">
      <label for="password">{{ $t("password") }}</label>
      <md-input name="password" type="password" v-model="password" />
    </md-field>
    <div>
      <md-card-actions>
        <md-button
          type="submit"
          class="md-layout md-size-100 md-raised"
          :disabled="processingLogin"
          >{{ $t("login") }}</md-button
        >
      </md-card-actions>
    </div>
  </form>
</template>

<script>
import { LOGIN, ALERT } from "@/store/actions.types.js";
import { required } from "vuelidate/lib/validators";

export default {
  name: "LoginForm",
  props: {
    redirect: Boolean,
    isDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      processingLogin: false,
      username: null,
      password: null,
      timeout: null,
    };
  },
  validations: {
    username: {
      required,
    },
    password: {
      required,
    },
  },
  methods: {
    async onSubmit(username, password) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.processingLogin = true;

      try {
        await this.$store.dispatch(LOGIN, {
          name: username,
          password,
        });

        if (this.isDialog) {
          console.log();
        } else {
          this.$router.push({ name: "Home" });
        }
        // this.$store.dispatch(ALERT, { message: 'Prueba' });
      } catch (e) {
        console.log(e);
        if (this.isDialog) {
          console.log();
        } else {
          this.$store.dispatch(ALERT, { message: this.$t('incorrect_password') });
        }
        this.processingLogin = false;
      }
    },

  },
};
</script>